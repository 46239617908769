<style lang="scss" scoped>
@import "assets/scss/default.scss";
</style>

<template>
	<HeaderPrinters/>
    <NavbarPrinters/>
    <main>
        <slot/>
    </main>
</template>

<script>
import HeaderPrinters from "~/components/headerPrinters.vue";

export default {
    name: "Default",
	components: { HeaderPrinters },
    async beforeCreate() {
        const { status, refreshAuthStatus } = await useAuth();
        /*refreshAuthStatus();
        const { status } = useAuth();
        if (status === "unauthenticated") this.$router.replace("/login");*/
    },
    mounted() {
        const { $events } = useNuxtApp();
        // Loader
        $events.on("loading", ([state, text, fullHeight]) => {
            this.loadingText = text || "loader.loading";
            this.loading = state;
            this.fullHeight = fullHeight;

            // If loading add overflow hidden
            if (state) document.body.classList.add("overflow-hidden");
            else document.body.classList.remove("overflow-hidden");
            // Move the scroll to the top
            window.scrollTo(0, 0);
        });
    }
};
</script>
